import { combineReducers } from "redux";

import theme from "./theme";
import auth from "./auth";
import user from "./user";
import device from "./device";
import transaction from "./transaction";
import commission from "./commission";
import configuration from "./configuration";
import product from "./product";
import order from "./order";
import store_transaction from "./store_transaction";

export default combineReducers({
    theme,
    auth,
    user,
    device,
    transaction,
    commission,
    configuration,
    product,
    order,
    store_transaction,
});
